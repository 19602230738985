import axios from 'axios'
import keyMirror from 'lib/keyMirror'
import React from "react"
import t from 't'

import {close as closePopupDialog} from 'actions/popupDialog'
import {close as closePopup} from 'actions/popup'
import {notice} from 'actions/notice'

export const types = keyMirror({
    LOAD_CITIES_REQUEST: null,
    LOAD_CITIES_OK: null,
    LOAD_CITIES_ERROR: null,

    LOAD_CALC_CITY_REQUEST: null,
    LOAD_CALC_CITY_OK: null,
    LOAD_CALC_CITY_ERROR: null,

    LOAD_CALC_CITY_SELECT: null,

    LOAD_CALC_CITIES_REQUEST: null,
    LOAD_CALC_CITIES_OK: null,
    LOAD_CALC_CITIES_ERROR: null,

    LOAD_CALC_PRO_REQUEST: null,
    LOAD_CALC_PRO_OK: null,
    LOAD_CALC_PRO_ERROR: null,

    ADD_CITY_SEARCH_REQUEST: null,
    ADD_CITY_SEARCH_OK: null,
    ADD_CITY_SEARCH_ERROR: null,

    REMOVE_CITY_REQUEST: null,
    REMOVE_CITY_OK: null,
    REMOVE_CITY_ERROR: null,

    REFUND_CANCEL_REQUEST: null,
    REFUND_CANCEL_OK: null,
    REFUND_CANCEL_ERROR: null,

    LOAD_MYALBUMS_REQUEST: null,
    LOAD_MYALBUMS_OK: null,
    LOAD_MYALBUMS_ERROR: null,

    LOAD_MEDIASTAT_REQUEST: null,
    LOAD_MEDIASTAT_OK: null,
    LOAD_MEDIASTAT_ERROR: null,
}, 'my');

export const loadCalcPro = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_CALC_PRO_REQUEST
        })

        axios.get(API_URL + '/v2/my/calcPro', {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_CALC_PRO_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_CALC_PRO_ERROR
                })
            })
    }
}

export const loadCities = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_CITIES_REQUEST
        })
        axios.get(API_URL + '/v2/my/cities', {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_CITIES_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_CITIES_ERROR
                })
            })
    }
}

export const loadCalcCity = (id, extraWantIds) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_CALC_CITY_REQUEST
        })
        axios.get(API_URL + '/v2/my/calcCity/' + id + '?extra_want_ids=' + extraWantIds.join(','), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_CALC_CITY_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_CALC_CITY_ERROR
                })
            })
    }
}

export const selectCalcCity = (city, price) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_CALC_CITY_SELECT,
            city: city,
            price: price
        })
        return Promise.resolve()
    }
}

export const loadCalcCities = (selectedCalcCities = {}) => {
    return function(dispatch, getState) {
        dispatch({
            type: types.LOAD_CALC_CITIES_REQUEST
        })
        if (selectedCalcCities === {}) {
            selectedCalcCities = getState.my.selectedCalcCities
        }
        axios.get(API_URL + '/v2/my/calcCities?city=' + encodeURIComponent(selectedCalcCities), {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_CALC_CITIES_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_CALC_CITIES_ERROR
                })
            })
    }
}

export const addCitySearch = (name, city, country) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.ADD_CITY_SEARCH_REQUEST
        })
        let perPage = 10
        return axios.get(API_URL + '/v2/cities?name=' + name + '&per_page=' + perPage + '&only_cities=1&-id=' + city.id + '&country_id=' + country.id, {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.ADD_CITY_SEARCH_OK,
                    data: response.data,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.ADD_CITY_SEARCH_ERROR
                })
            })
    }
}

export const removeCity = (city) => {
    return function (dispatch, getState) {
        dispatch({
            type: types.REMOVE_CITY_REQUEST
        })
        if (city.is_new) {
            dispatch({
                type: types.REMOVE_CITY_OK,
                city: city,
            })
            dispatch(closePopupDialog())
        } else {
            axios.delete(API_URL + '/v2/bill/cities', {data:JSON.stringify({cities: [city.city.id]}), withCredentials: true})
                .then(response => {
                    dispatch({
                        type: types.REMOVE_CITY_OK,
                        city: city,
                    })
                    dispatch(closePopupDialog())
                })
                .catch((e) => {
                    if ('development' === process.env.NODE_ENV) console.log(e)
                    dispatch({
                        type: types.REMOVE_CITY_ERROR
                    })
                })
        }
    }
}

export const refundOrder = (sum, cardNumber) => {
    return new Promise((resolve, reject) => {
        axios.put(API_URL + '/v2/my/refund', {sum: sum, card_number: cardNumber},{withCredentials: true})
        .then(response => {
            resolve(response.data)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const refundCancel = () => {
    return function(dispatch, getState) {
        dispatch({
            type: types.REFUND_CANCEL_REQUEST
        })
        axios.delete(API_URL + '/v2/my/refund', {withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.REFUND_CANCEL_OK,
                    data: response.data,
                })
                dispatch(closePopupDialog())
                window.location.reload()
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.REFUND_CANCEL_ERROR
                })
            })
    }
}

export const loadMyAlbums = (categoryLabel, params, page, perPage) => {
    return function(dispatch, getState) {
        if (!page) {
            page = 1
        }
        if (!perPage) {
            perPage = 20
        }
        params = Object.assign({
            embed        : 'cover',
            preview_size : '290x155x1',
            page         : page,
            per_page     : perPage,
        }, params)


        dispatch({
            type: types.LOAD_MYALBUMS_REQUEST,
            page : page,
            category : categoryLabel,
        })
        axios.get(API_URL + '/v2/my/albums', {params:params, withCredentials: true})
            .then(response => {
                dispatch({
                    type     : types.LOAD_MYALBUMS_OK,
                    data     : response.data,
                    category : categoryLabel,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_MYALBUMS_ERROR,
                    category : categoryLabel,
                })
            })
    }
}

export const loadMediaStat = (order, page, perPage) => {
    return function(dispatch, getState) {
        if (!page) {
            page = 1
        }
        if (!perPage) {
            perPage = 100
        }

        dispatch({
            type: types.LOAD_MEDIASTAT_REQUEST,
            page: page,
        })

        let params = {
            page         : page,
            per_page     : perPage,
            preview_size : '96x96x1',
        }
        if (order == 'comments' || order == 'likes') {
            params.order = order
        }
        axios.get(API_URL + '/v2/my/mediaStat', {params:params, withCredentials: true})
            .then(response => {
                dispatch({
                    type: types.LOAD_MEDIASTAT_OK,
                    list: response.data.stat,
                    meta: response.data.meta,
                })
            })
            .catch((e) => {
                if ('development' === process.env.NODE_ENV) console.log(e)
                dispatch({
                    type: types.LOAD_MEDIASTAT_ERROR
                })
            })
    }
}

export const loadSocials = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/auth/socials/', {withCredentials: true})
        .then(response => resolve(response.data.socials))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const unlinkSocial = (socialId) => {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + '/v2/auth/socials/' + socialId, {withCredentials: true})
        .then(response => resolve(null))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const loadAccounts = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/auth/users/', {withCredentials: true})
        .then(response => resolve(response.data.users))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const loadAbout = () => {
    return new Promise(function(resolve, reject) {
        axios.get(API_URL + '/v2/my/about', {withCredentials: true})
        .then(res => resolve(res.data.about))
    })
}

export const loadProfile = (type) => {
    return new Promise(function(resolve, reject) {
        let params = {
            fields : 'params,district_list,contacts,related_id',
            is_edit : 1,
        }
        axios.get(API_URL + '/v2/my/', {params: params, withCredentials: true})
        .then(res => {
            const user = res.data.user
            if (type == 'user') {
                resolve(user)
            } else {
                if (type == 'shop') {
                    params = {
                        embed : 'shopBranches',
                    }
                } else {
                    params.fields = 'metro,district,params,params_sort,contacts,specs,locale_data'
                }
                axios.get(API_URL + '/v2/' + type + 's' + '/' + user.related_id, {params: params, withCredentials: true})
                .then(res => {
                    let profile = res.data[type]
                    if (type == 'shop') {
                        profile.user_id = user.id
                        profile.role = user.role
                        profile.avatar_url = user.avatar_url
                        profile.district_list = user.district_list ?? null
                    }
                    resolve(profile)
                })
            }
        })
    })
}

export const saveProfile = (data, type, relatedId) => {
    return new Promise(function(resolve, reject) {
        let url = API_URL + '/v2/my/'
        if (type != 'user') {
            url = API_URL + '/v2/' + type + 's' + '/' + relatedId
            if (type != 'shop') {
                url += '?fields=locale_data'
            }
        }
        axios.put(url, JSON.stringify(data),{
            headers: {'content-type' : 'application/json'},
            withCredentials: true,
        })
        .then(response => resolve(response.data[type]))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
            reject()
        })
    })
}

let localeListCache = null
export const loadLocaleList = (url) => {
    return new Promise(function(resolve, reject) {
        if (localeListCache !== null) {
            resolve(localeListCache)
        } else {
            axios.get(API_URL + '/v2/sites?url=' + (url ?? document.location.origin + '/'), {withCredentials: true})
            .then(res => {
                let checkUrl = document.location.hostname.replace('www.', '')
                localeListCache = res.data.sites.filter(site => site.url.includes(checkUrl))
                resolve(localeListCache)
            })
        }
    })
}

export const getSrostCode = () => {
    return new Promise(function(resolve, reject) {
        axios.get(API_URL + '/v2/vgorko/caramelCode', {withCredentials: true})
        .then(response => resolve(response.data))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
        })
    })
}

export const relocateInfo = (cityId) => {
    return new Promise(function(resolve, reject) {
        axios.get(API_URL + '/v2/my/cityChange?city_id=' + +cityId, {withCredentials: true})
        .then(response => resolve(response.data))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
        })
    })
}

export const changeCity = (cityId) => {
    return new Promise(function(resolve, reject) {
        axios.put(API_URL + '/v2/my/cityChange', JSON.stringify({city_id: +cityId}), {withCredentials: true})
        .then(response => resolve(true))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
        })
    })
}

export const loadNotify = () => {
    return new Promise(function(resolve, reject) {
        axios.get(API_URL + '/v2/noticeTypes?per_page=100', {withCredentials: true})
        .then(response => resolve(response.data.notice_types))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
        })
    })
}

export const saveNotify = (data) => {
    return new Promise(function(resolve, reject) {
        axios.post(API_URL + '/v2/noticeTypes', JSON.stringify({data:data}), {
            headers: {'content-type' : 'application/json'},
            withCredentials: true
        })
        .then(response => resolve(response))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
            console.error('save error')
        })
    })
}

export const loadBilling = (page, perPage) => {
    page = page ?? 1
    perPage = perPage ?? 10
    let params = [
        'page=' + page,
        'per_page=' + perPage,
    ]
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/bill/list?' + params.join('&'), {withCredentials: true})
        .then(response => {
            resolve({
                list : response.data.billingHistory,
                meta : response.data.meta,
            })
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const redeemCode = (code) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + '/v2/bill/redeem', {code: code}, {withCredentials: true})
        .then(response => {
            resolve(response.data)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const uploadAvatar = (file, url) => {
    return new Promise((resolve, reject) => {
        if (!url) {
            url = API_URL + '/v2/my/avatar'
        } else if (!url.includes(API_URL)) {
            url = API_URL + url
        }
        let data = new FormData()
        data.append('file', file)
        axios.post(url, data, {
            headers : 'multipart/form-data',
            withCredentials : true,
        })
        .then(response => resolve(response.data))
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

// prices
export const loadHolidays = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/interface/holidays', {withCredentials: true})
        .then(response => {
            resolve(response.data.holidays)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
export const loadSpecs = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/my/specs?isList=1', {withCredentials: true})
        .then(response => {
            resolve(response.data.specs)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
export const loadSpecPrices = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/my/specPrices', {withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
export const loadPrices = () => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + '/v2/my/prices', {withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
export const savePrice = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + '/v2/my/prices', JSON.stringify(data), {withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
export const deletePrice = (priceId) => {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + '/v2/my/prices', {data:JSON.stringify({id: priceId}), withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const addSpecs = (ids) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + '/v2/my/specs', JSON.stringify({ids:ids}), {withCredentials: true})
        .then(response => {
            resolve(response.data.specs)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const specPricesSort = (ids, specId) => {
    const data = {
        prices  : ids,
        spec_id : specId,
    }
    return new Promise((resolve, reject) => {
        axios.post(API_URL + '/v2/my/specPricesSort', JSON.stringify(data), {withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const removeSpec = (specId) => {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + '/v2/my/specs', {data:JSON.stringify({id: specId}), withCredentials: true})
        .then(response => {
            resolve(response.data.specs)
        })
        .catch(() => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}

export const cardPricesSort = (ids, specId) => {
    const data = {
        prices  : ids,
        spec_id : specId,
    }
    return new Promise((resolve, reject) => {
        axios.post(API_URL + '/v2/my/cardPrices', JSON.stringify(data), {withCredentials: true})
        .then(response => {
            resolve(response.data.prices)
        })
        .catch((e) => {
            if ('development' === process.env.NODE_ENV) console.log(e)
        })
    })
}
