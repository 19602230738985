import md5 from 'md5'

import BaseModel from 'models/baseModel'

const FBV = 'v14.0'

export default class AuthMethod extends BaseModel {
    get oAuthUrl() {
        switch (this.key) {
            case 'vk':
                return 'https://oauth.vk.com/authorize?'
            case 'fb':
                return 'https://www.facebook.com/' + FBV + '/dialog/oauth?'
            case 'goo':
                return 'https://accounts.google.com/o/oauth2/auth?'
            case 'odn':
                return 'https://connect.ok.ru/oauth/authorize?'
            case 'ml':
                return 'https://connect.mail.ru/oauth/authorize?'
            default:
                return null
        }
    }

    get oAuthParams() {
        const redirectUri = SITE_URL.replace(/:\d+/, '') + '/future/redirectUri.html'
        switch (this.key) {
            case 'vk':
                return {
                    client_id: this.auth_id,
                    display: 'page',
                    scope: 'email offline',
                    response_type: 'token',
                    redirect_uri: redirectUri + '?from=' + this.key,
                    v: 5.131
                }
            case 'fb':
                return {
                    client_id: this.auth_id,
                    display: 'page',
                    scope: 'email',
                    response_type: 'code',
                    redirect_uri: SITE_URL.replace(/:\d+/, '') + '/Login/recieve?from=' + this.key
                }
            case 'goo':
                return {
                    client_id: this.auth_id,
                    scope: 'profile email',
                    response_type: 'token',
                    redirect_uri: redirectUri + '?from=' + this.key
                }
            case 'odn':
                return {
                    client_id: this.auth_id,
                    scope: 'VALUABLE_ACCESS',
                    response_type: 'token',
                    redirect_uri: redirectUri + '?from=' + this.key,
                    layout: 'w',
                    state: this.app_key
                }
            case 'ml':
                return {
                    client_id: this.auth_id,
                    response_type: 'token',
                    display: 'mobile',
                    redirect_uri: redirectUri + '?from=' + this.key
                }
            default:
                return {}
        }
    }

    apiParams(params) {
        let sig = ''
        switch (this.key) {
            case 'vk':
                return {
                    user_ids: params.user_id,
                    fields: 'sex,city,photo_200,email,nickname,photo_id',
                    access_token: params.access_token,
                    v: 5.131
                }
            case 'fb':
                return {
                    fields: 'email,first_name,last_name,gender,picture.width(200).height(200),name,location',
                    access_token: params.access_token
                }
            case 'goo':
                return {
                    access_token: params.access_token
                }
            case 'odn':
                sig = md5(
                    'application_key=' + this.app_key + 'fields=FIRST_NAME,LAST_NAME,GENDER,PIC_3,PHOTO_ID,LOCATION,NAME,HAS_EMAILformat=JSONmethod=users.getCurrentUser' + params.session_secret_key
                )
                return {
                    application_key: this.app_key,
                    method: 'users.getCurrentUser',
                    format: 'JSON',
                    fields: 'FIRST_NAME,LAST_NAME,GENDER,PIC_3,PHOTO_ID,LOCATION,NAME,HAS_EMAIL',
                    access_token: params.access_token,
                    sig: sig
                }
            case 'ml':
                sig = md5(
                    params.x_mailru_vid + 'app_id=' + this.auth_id + 'method=users.getInfosession_key=' + params.access_token + this.app_key
                )
                return {
                    app_id: this.auth_id,
                    method: 'users.getInfo',
                    session_key: params.access_token,
                    sig: sig
                }
            default:
                return null
        }
    }

    get apiUrl() {
        switch (this.key) {
            case 'vk':
                return 'https://api.vk.com/method/users.get'
            case 'fb':
                return 'https://graph.facebook.com/' + FBV + '/me'
            case 'goo':
                return 'https://www.googleapis.com/plus/v1/people/me'
            case 'odn':
                return 'https://api.odnoklassniki.ru/fb.do'
            case 'ml':
                return 'https://connect.mail.ru/getLoginStatus'
//                return 'https://www.appsmail.ru/platform/api'
            default:
                return null
        }
    }
}
