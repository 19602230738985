import {types} from 'actions/auth'
import {types as interfaceTypes} from 'actions/main'

import _ from 'lodash'

import AuthMethod from 'models/authMethod'
import AuthUser from 'models/authUser'

const initialState = {
    current_social_key: null,
    isAuth: false,
    isChecked: false, // для первой загрузки application,
    methods: [],
    need_register: false,
    social_data: {},
    user: null,
    userMenu: [],
    error: false,
}

const hasMobile = [
    '',
    'account',
    'billing',
    'billingPro',
    'cities',
//    'freeDates',
    'notify',
    'photoalbum',
    'price',
//    'rating',
    'settings',
    'specs',
]

const prepareMenu = (srcMenu) => {
    let menu = []
    if (srcMenu?.length) {
        menu = srcMenu[0]
        if (srcMenu?.[1]) {
            menu = menu.concat(srcMenu[1])
        }
        menu.forEach(item => {
            let matches = item.url.match(/my\/(.*?)($|\/)/)
            item.hasMobile = hasMobile.includes(matches?.[1])
        })
    }
    return menu
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case interfaceTypes.LOAD_INTERFACE_OK:
            return {
                ...state,
                isChecked: true,
                isAuth: !!action.auth,
                user: action.auth ? new AuthUser(action.auth) : null,
                userMenu: prepareMenu(action.auth.menu),
                error: false,
            }
        case types.CHECK_AUTH_OK:
            return {
                ...state,
                isChecked: true,
                isAuth: !!action.user,
                user: action.user ? new AuthUser(action.user) : null,
                need_register: false,
                current_social_key: action.social_key ? action.social_key : null,
                userMenu: prepareMenu(action.user.menu),
            }

        case types.CHECK_AUTH_REQUEST:
            return {
                ...state,
                isAuth: false,
                user: null,
                error: false,
            }
        case types.CHECK_AUTH_ERROR:
            return {
                ...state,
                isChecked: true,
                isAuth: false,
                user: null,
            }
        case types.CHECK_ADDITION_REQUEST:
            return {
                ...state,
                error: false,
            }
        case types.CHECK_ADDITION_ERROR:
            return {
                ...state,
                error: action.error,
            }

        case types.LOAD_METHODS_OK:
            return {
                ...state,
                methods: _.map(action.methods, method => new AuthMethod(method)),
            }

        case types.LOAD_METHODS_REQUEST:
        case types.LOAD_METHODS_ERROR:
            return {
                ...state,
                methods: [],
                error: false,
            }

        case types.AUTH_NO_USER:
            return {
                ...state,
                need_register: true,
                social_data: action.social_data,
                current_social_key: action.social_key,
            }

        case types.MODERATION_REQUEST_OK:
            return {
                ...state,
                user: {
                    ...state.user,
                    allow_pro_status: action.data.allow_pro_status
                },
            }

        case types.AUTH_START:
            return {
                ...state,
                need_register: false,
                social_data: {},
                current_social_key: null,
            }

        default:
            return state
    }
}
export default AuthReducer
